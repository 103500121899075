import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { requestWrapper } from 'lib/api/auth';
import { useSearchParams } from 'react-router-dom';
import { BASE_URL } from 'lib/api/client';
import { useTranslation } from 'react-i18next';

interface ShareInstanceOptionsProps {
  onOptionChange: (option: string) => void;
  initialDocumentId?: string;
}

export interface DocumentOption {
  id: string;
  document_id: string;
  code: string;
}

export const ShareInstanceOptions: React.FC<ShareInstanceOptionsProps> = ({ onOptionChange, initialDocumentId }) => {
  const [searchParams] = useSearchParams();
  const passcodeId = searchParams.get('passcodeId') || '';

  
  const [options, setOptions] = useState<DocumentOption[]>([]);
  const [currentValue, setCurrentValue] = useState<string | null>(null);

  const fetchOptions = useCallback(async () => {
    try {
      const docuId = searchParams.get('docuId') || initialDocumentId;
      if (docuId) {
        const data = await requestWrapper(`${BASE_URL}/api/v1/documents/${docuId}/passcodes`);
        setOptions(data);
        const paramPasscode = verifyAndReturnRecord(data, passcodeId);
        if (paramPasscode) {
          setCurrentValue(paramPasscode.code);
          onOptionChange(paramPasscode.id);
        } else if (data.length > 0) {
          const lastOption = data[0];
          setCurrentValue(lastOption.code);
          onOptionChange(lastOption.id);
        } else {
          setOptions([]);
          onOptionChange('');
        }
      } else {
        setOptions([]);
        onOptionChange('');
      }
    } catch (error) {
      console.error('Failed to fetch options', error);
      setOptions([]);
      onOptionChange('');
    }
  }, [searchParams, initialDocumentId, onOptionChange]);

  useEffect(() => {
    setCurrentValue(null);
  }, [initialDocumentId]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const handleOptionChange = (event: any, value: string | null) => {
    setCurrentValue(value || '');
    const selectedOption = options.find(option => option.code === value);
    onOptionChange(selectedOption?.id || '');
  };

  type RecordType = {
    code: string;
    id: string;
    [key: string]: any; // other properties can be present
  };

  const verifyAndReturnRecord = (data: RecordType, code: string): RecordType => {
    const record = data.find((record: { code: string; }) => record.code === code);
    return record || null;
  }

  const { t } = useTranslation("global");

  return (
    <Autocomplete
      id="document-option-autocomplete"
      freeSolo
      options={options.map((option) => option.code)}
      value={currentValue}
      onChange={handleOptionChange}
      renderInput={(params) => (
        <TextField {...params} label={t("mainSection.user-search-label")} variant="outlined" size="small" />
      )}
      style={{ minWidth: "30%" }}
      clearOnEscape
    />
  );
};

export default ShareInstanceOptions;
