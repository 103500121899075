import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TableSortLabel, TextField, Box, TablePagination, Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { requestWrapper } from 'lib/api/auth';
import { BASE_URL } from 'lib/api/client';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BASE_COLOR } from 'components/utils/Constants';

interface VisitorEngagement {
  id: number;
  code: string;
  total_duration_minutes: number;
  session_count: number;
  last_session_time: string;
}

interface VisitorEngagementTableProps {
  documentId: string;
}

const VisitorEngagementTable: React.FC<VisitorEngagementTableProps> = ({ documentId }) => {
  const [data, setData] = useState<VisitorEngagement[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<keyof VisitorEngagement>('total_duration_minutes');
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Default date range: past month to today
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());

  const { t } = useTranslation("global");
  
  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const response = await requestWrapper(
          `${BASE_URL}/api/v1/visitor_engagement?docuId=${documentId}&start_date=${startDate?.format('YYYY-MM-DD')}&end_date=${endDate?.format('YYYY-MM-DD')}`
        );
        if (response && response["data"]) {
          const data = response["data"]
          setData(data);
          if (data.length > 25) {
            setRowsPerPage(25);
          } else if (data.length > 10) {
            setRowsPerPage(10);
          } else {
            setRowsPerPage(5);
          }
        }
      } catch (err) {
        // handle error
      }
    };

    fetchSummary();
  }, [documentId, startDate, endDate]);

  const handleRequestSort = (property: keyof VisitorEngagement) => {
    if (orderBy !== property) {
      setOrder('desc');
      setOrderBy(property);
    } else {
      setOrder(order === 'asc' ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = data.filter(passcode => passcode.code.toLowerCase().includes(filter.toLowerCase()));

  const sortedData = filteredData.sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === 'asc' ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const handleStartDateChange = (newStartDate: Dayjs | null) => {
    setStartDate(newStartDate);
    if (endDate && newStartDate && newStartDate.isAfter(endDate)) {
      setEndDate(newStartDate);
    }
  };

  const handleEndDateChange = (newEndDate: Dayjs | null) => {
    if (startDate && newEndDate && newEndDate.isBefore(startDate)) {
      setEndDate(startDate);
    } else {
      setEndDate(newEndDate);
    }
  };

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ fontWeight: 'bold', paddingTop: '8px' }}>
        {t("mainSection.engagement-section-title")}
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginY={2} padding={'8px'}>
        <TextField
          label={t("mainSection.email-search-label")}
          variant="outlined"
          value={filter}
          onChange={handleFilterChange}
          style={{ flex: 1, marginRight: '16px' }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={t("mainSection.start-date")}
            value={startDate}
            onChange={handleStartDateChange}
            format="YYYY-MM-DD"
            slotProps={{ textField: { variant: 'outlined', style: { marginRight: '16px' } } }}
          />
          <DatePicker
            label={t("mainSection.end-date")}
            value={endDate}
            onChange={handleEndDateChange}
            format="YYYY-MM-DD"
            slotProps={{ textField: { variant: 'outlined' } }}
          />
        </LocalizationProvider>
      </Box>
      <TableContainer component={Paper} style={{padding: '8px'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'code'}
                  direction={orderBy === 'code' ? order : 'asc'}
                  onClick={() => handleRequestSort('code')}
                >
                  <Typography variant="body2" fontWeight="bold">
                  {t("mainSection.email-label")}
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'total_duration_minutes'}
                  direction={orderBy === 'total_duration_minutes' ? order : 'asc'}
                  onClick={() => handleRequestSort('total_duration_minutes')}
                >
                  <Typography variant="body2" fontWeight="bold">
                    {t("mainSection.total-time")}
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'session_count'}
                  direction={orderBy === 'session_count' ? order : 'asc'}
                  onClick={() => handleRequestSort('session_count')}
                >
                  <Typography variant="body2" fontWeight="bold">
                    {t("mainSection.session-count")}
                  </Typography>
                </TableSortLabel>
              </TableCell>

              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'last_session_time'}
                  direction={orderBy === 'last_session_time' ? order : 'asc'}
                  onClick={() => handleRequestSort('last_session_time')}
                >
                  <Typography variant="body2" fontWeight="bold">
                    {t("mainSection.latest-view-date")}
                  </Typography>
                </TableSortLabel>
              </TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((passcode) => (
              <TableRow key={passcode.id}>
                <TableCell>
                  <Link
                    to={`/analytics?docuId=${documentId}&passcodeId=${passcode.code}`}
                    style={{
                      color: BASE_COLOR,
                      textDecoration: 'none',
                      fontWeight: '500',
                      borderBottom: '2px solid transparent',
                      paddingBottom: '2px',
                      transition: 'color 0.2s ease, border-bottom-color 0.2s ease',
                    }}
                    onMouseOver={(e) => {
                      const target = e.target as HTMLElement;
                      target.style.color = '#303f9f';
                      target.style.borderBottomColor = '#303f9f';
                    }}
                    onMouseOut={(e) => {
                      const target = e.target as HTMLElement;
                      target.style.color = '#3f51b5';
                      target.style.borderBottomColor = 'transparent';
                    }}
                  >
                    {passcode.code}
                  </Link>
                </TableCell>
                <TableCell align="right">{passcode.total_duration_minutes.toFixed(2)}</TableCell>
                <TableCell align="right">{passcode.session_count}</TableCell>
                <TableCell align="right">{dayjs(passcode.last_session_time).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default VisitorEngagementTable;
