import React, { useState, useEffect, createContext, ReactNode } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import CommonLayout from "components/layouts/CommonLayout";
import SignUp from "components/pages/SignUp";
import SignIn from "components/pages/SignIn";
import { getCurrentUser } from "lib/api/auth";
import { User } from "interfaces/index";
import UserProfilePage from "components/pages/UserProfilePage";
import SearchPage from "components/pages/Search";
import AnalyticsPage from "components/pages/anaylytics/Analytics";
import PdfPage from "components/pages/PDFPage";
import ProtectPDFPage from "components/pages/ProtectPDFPage";
import Cookies from "js-cookie";
import SalesforceSettings from "components/pages/external/SalesForceSettings";
import Dashbaord from "components/pages/anaylytics/dashboard/Dashboard";
import MobileWarningDialog from "MobileWarningDialog";
import Flow from "components/pages/anaylytics/dashboard/Flow";
import ChatWorkSettings from "components/pages/external/ChatWorkSettings";
import ScrollToTop from "components/utils/ScrollToTop";

export const AuthContext = createContext({} as {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isSignedIn: boolean | undefined;
  setIsSignedIn: React.Dispatch<React.SetStateAction<boolean>>;
  currentUser: User | undefined;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | undefined>>;
});

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSignedIn, setIsSignedIn] = useState(() => {
    return localStorage.getItem('isSignedIn') === 'true';
  });
  const [isPaidUser, setIsPaidUser] = useState(() => {
    return localStorage.getItem('isPaidUser') === 'false';
  });
  const [currentUser, setCurrentUser] = useState<User | undefined>();
  const [mobileWarningOpen, setMobileWarningOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const unauthenticatedPaths = ["/signup", "/signin", "/shared"];

  const handleGetCurrentUser = async () => {
    try {
      const res = await getCurrentUser();
      if (res.data.failed) {
        setIsSignedIn(false);
        localStorage.setItem('isSignedIn', isSignedIn.toString());
        setCurrentUser(undefined);
        navigate('/signin')
      } else {
        setIsSignedIn(true);
        localStorage.setItem('isSignedIn', isSignedIn.toString());
      }
    } catch (err) {
      setIsSignedIn(false);
      localStorage.setItem('isSignedIn', isSignedIn.toString());
      setCurrentUser(undefined);
    }

    setLoading(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile && !location.pathname.startsWith('/shared')) {
      setMobileWarningOpen(true);
    } else {
      setMobileWarningOpen(false);
    }
  }, [isMobile, location.pathname]);

  useEffect(() => {
    if (!unauthenticatedPaths.includes(location.pathname)) {
      handleGetCurrentUser();
      const _isPaidUser = Cookies.get("_isPaidUser") === "true";
      setIsPaidUser(_isPaidUser);
    } else {
      setLoading(false);
    }
  }, [isSignedIn, isPaidUser]);

  useEffect(() => {
    const handleTokenChange = () => {
      if (!Cookies.get("_access_token") || !Cookies.get("_client") || !Cookies.get("_uid")) {
        setIsSignedIn(false);
        localStorage.setItem('isSignedIn', isSignedIn.toString());
        setCurrentUser(undefined);
      }
    };

    window.addEventListener("storage", handleTokenChange);

    return () => {
      window.removeEventListener("storage", handleTokenChange);
    };
  }, [isSignedIn]);

  return (
    <AuthContext.Provider value={{
      loading,
      setLoading,
      isSignedIn,
      setIsSignedIn,
      currentUser,
      setCurrentUser
    }}>
      {children}
      {<MobileWarningDialog open={mobileWarningOpen} />}
    </AuthContext.Provider>
  );
};

const PrivateRoute = ({ children }: { children: React.ReactElement }) => {
  const { loading, isSignedIn } = React.useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  return isSignedIn ? children : <Navigate to="/signin" />;
};

const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <CommonLayout>
          <ScrollToTop/>
          <Routes>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/shared" element={<ProtectPDFPage />} />
            <Route path="/flow" element={<Flow />} />
            <Route path="/settings/salesforce" element={<SalesforceSettings />} />
            <Route path="/settings/chatworks" element={<ChatWorkSettings />} />
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Dashbaord/>
                </PrivateRoute>
              }
            />
            <Route
              path="/files"
              element={
                <PrivateRoute>
                  <SearchPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/me"
              element={
                <PrivateRoute>
                  <UserProfilePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/view"
              element={
                <PrivateRoute>
                  <PdfPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/analytics"
              element={
                <PrivateRoute>
                  <AnalyticsPage />
                </PrivateRoute>
              }
            />
          </Routes>
        </CommonLayout>
      </AuthProvider>
    </Router>
  );
};

export default App;
